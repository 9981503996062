<template>
  <v-form v-model="isValid" @submit.prevent="submit">
    <v-text-field v-model="form.email" :label="$t('field.email')" autocomplete="none" :rules="rules"/>

    <div class="d-flex">
      <v-spacer/>
      <v-btn text class="mr-2" @click="cancel">{{ $t('action.cancel') }}</v-btn>
      <v-btn color="primary" :disabled="!isValid" type="submit">{{ $t('action.reset') }}</v-btn>
    </div>
  </v-form>
</template>

<script lang="ts">
import {User} from '@cat-europe/common/web';
import Vue from 'vue';
import {Component} from 'vue-property-decorator';

@Component
export default class ResetPasswordRequestForm extends Vue {
  isValid = false;

  form = {
    email: '',
  };

  async submit() {
    try {
      await this.$api.put('/api/auth/reset-password/request', this.form);
      this.$notify.success(this.$t('msg.resetPasswordLinkSentToEmail', {email: this.form.email}));
      this.$emit('success');
    } catch (err: any) {
      if (err?.response?.status === 404) {
        this.$notify.error(this.$t('notify.unknownEmail'));
      } else {
        this.$notify.error(err);
      }
    }
  }

  cancel() {
    this.form.email = '';
    this.$emit('cancel');
  }

  get rules() {
    return User.ruleSet(this.$i18n.locale).email;
  }
}
</script>
