import {i18n} from '@cat-europe/common';
import deepmerge from 'deepmerge';

export const de = deepmerge(i18n.de, {
  action: {
    addToBasket: 'Zum Warenkorb hinzufügen',
    proceedToCheckout: 'Weiter zur Kasse',
    continueWithPaypal: 'Mit PayPal fortfahren',
    finalizeOrder: 'Bestellung verbindlich abschließen',
    assignProductAccessPass: '{product} Zugangspass zuweisen',
    assignProduct: 'Produkt zuweisen',
    confirmRemoveLastProduct: 'Letztes Produkt entfernen',
  },
  label: {
    organizationDetails: 'Organisationsdetails',
    orderConfirmed: 'Bestellung bestätigt',
    inventory: 'Inventar',
    tax: 'Steuern',
    subTotal: 'Zwischensumme',
    basket: 'Warenkorb',
    estimatedRemainingEffort: 'Geschätzter Restaufwand',
    progress: 'Fortschritt',
    archive: 'Archiv',
    analytics: 'Auswertungen',
    latestItems: 'Letzte {items}',
    quickAccess: 'Schnellzugriff',
    amount: 'Anzahl',
    quickQuestionnaire: 'Schnellbefragung',
    currentlyOwned: 'Aktuell im Inventar',
  },
  text: {
    sendInviteSuccess: 'Die Produkteinladungen wurden erfolgreich versandt',
    confirmRemoveLastProduct: 'Sind sie sich sicher, dass sie den letzten Artikel aus dieser Bestellung entfernen wollen? Sie werden daraufhin zur Produktübersicht zurückgebracht.',
    analysis: {
      questionnaireDescription: 'Klicke auf die Balken im Diagramm um der Befragung Fragen hinzuzufügen',
      noQuestionsSelected: 'Keine Fragen ausgewählt',
      noQuestionnaires: 'Keine Befragungen vorhanden',
    },
    checkout: {
      orderConfirmed: 'Deine Bestellung {orderDetails} ist bestätigt!',
      reviewOrder: 'Eine Übersicht aller Bestellung findest du im Bereich {orders}.',
      managePurchases: 'Um gekaufte Produkte zu verwalten, verwende den Bereich {inventory}',
      thanks: 'Vielen Dank!',
      addressWarning: 'Bestellungen können erst aufgegeben werden, wenn die benötigten Adressdaten in den {organizationDetails} eingetragen sind.',
    },
  },
  dialog: {
    startOverQuestionnaire: 'Möchtest du eine neue Instanz dieser Befragung starten?',
    moveItemToArchive: 'Möchtest du "{item}" in das Archiv verschieben?',
    restoreItemFromArchive: 'Möchtest du "{item}" aus dem Archiv zurückholen?',
    repeatWrong: 'Starte eine neue Befragung mit den falsch beantworteten Fragen',
    confirmAssignment: 'Eine oder mehrere der ausgewählten E-Mail-Adressen haben bereits einen identischen aktiven Zugangspass. Trotzdem zuordnen?',
  },
  notify: {
    renameItemSuccess: '{item} wurde erfolgreich umbenannt.',
    resetItemSuccess: '{item} wurde erfolgreich zurückgesetzt.',
    archiveItemSuccess: '{item} wurde erfolgreich in das Archiv verschoben.',
    restoreItemSuccess: '{item} wurde erfolgreich aus dem Archiv geholt.',
    changeEmailLinkSentSuccess: 'Ihnen wurde eine Email an {email} geschickt um die Änderung zu bestätigen.',
    feedbackSuccess: 'Vielen Dank für Ihr Feedback! Wir werden Sie falls nötig per Email kontaktieren.',
  },
  warning: {
    noValidAccessPasses: 'Dir sind keine gültigen Zugangspässe für den Question-Trainer zugewiesen.',
  },
  rule: {
    pendingAssignmentAlreadyExists: 'Ausstehende Zuweisung existiert bereits',
    identicalAccessPassAlreadyActive: 'Identischer Zugangspass bereits aktiv',
  },
  msg: {
    noPurchasesForCountry: 'Einkäufe sind aktuell nicht für Ihr Land aktiviert.',
    noPurchasesForReverseChargeWithoutVatId: 'Einkäufe aus Ihrem Land benötigen aktuell eine in Ihrer Organisation eingetragene Steuer ID.',
  },
});
