import {ProductData} from '@cat-europe/common';
import {Module} from 'vuex';
import {RootState} from '~/store/index';

function updateState(state) {
  window.localStorage.setItem('basket', JSON.stringify(state));
}

export interface BasketState {
  isOpen: boolean,
  items: {
    product: ProductData,
    amount: number,
  }[],
}

export const basketStore: Module<BasketState, RootState> = {
  namespaced: true,
  state: {
    isOpen: false,
    items: [],
  },
  mutations: {
    clear(state) {
      state.items.length  = 0;
      state.isOpen = false;
      updateState(state);
    },
    add(state, product) {
      const productIndex = state.items.findIndex(item => item.product.id === product.id);
      if (productIndex === -1) {
        state.items.push({product, amount: 1});
      } else {
        state.items[productIndex].amount++;
      }
      state.isOpen = true;
      updateState(state);
    },
    remove(state, productId) {
      const idx = state.items.findIndex(item => item.product.id === productId);
      state.items.splice(idx, 1);
      if (state.items.length === 0) {
        state.isOpen = false;
      }
      updateState(state);
    },
    setIsOpen(state, isOpen) {
      state.isOpen = isOpen;
      updateState(state);
    },
    decreaseAmount(state, productId) {
      state.items.find(item => item.product.id === productId).amount--;
      updateState(state);
    },
    increaseAmount(state, productId) {
      state.items.find(item => item.product.id === productId).amount++;
      updateState(state);
    },
    loadFromLocalStorage(state) {
      const item = window.localStorage.getItem('basket');
      const basket = JSON.parse(item);
      if (basket) {
        state.isOpen = basket.isOpen;
        state.items = basket.items;
      }
    },
  },
  actions: {
    setIsOpen({commit}, isOpen: boolean) {
      commit('setIsOpen', isOpen);
    },
  },
  getters: {},
};
