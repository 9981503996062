<template>
  <v-app>
    <loading-indicator/>
    <app-header/>
    <app-navigation/>
    <app-basket/>

    <v-main class="mb-10">
      <transition name="page" mode="out-in">
        <slot/>
      </transition>
    </v-main>

    <app-footer/>
  </v-app>
</template>

<script lang="ts">
import LoadingIndicator from '@cat-europe/common/web/components/loading-indicator.vue';
import Vue from 'vue';
import {Component} from 'vue-property-decorator';
import AppBasket from '~/components/layout/app-basket.vue';
import AppFooter from '~/components/layout/app-footer.vue';
import AppHeader from '~/components/layout/app-header.vue';
import AppNavigation from '~/components/layout/app-navigation.vue';

@Component({
  components: {AppFooter, AppHeader, AppNavigation, AppBasket, LoadingIndicator},
})
export default class AppLayout extends Vue {
}
</script>


<style lang="scss">
.page-enter-active,
.page-leave-active {
  transition: opacity 0.15s, transform 0.2s;
}

.page-enter {
  opacity: 0;
  transform: translateY(-10%);
}

.page-leave-to {
  opacity: 0;
  transform: translateY(5%);
}
</style>
