<template>
  <v-app-bar app clipped-left dark color="primary" short>
    <v-app-bar-nav-icon @click="onNavIconClick"/>

    <router-link :to="{name: 'root'}">
      <img src="/img/logo.png"/>
    </router-link>
    <v-app-bar-title>
      <router-link :to="{name: 'root'}" class="app-title">
        {{ organizationName }}
      </router-link>
    </v-app-bar-title>
    <v-spacer/>
    <user-menu/>
  </v-app-bar>
</template>

<script lang="ts">
import Vue from 'vue';
import {Component} from 'vue-property-decorator';
import UserMenu from '~/components/layout/user-menu.vue';

@Component({
  components: {UserMenu},
})
export default class AppHeader extends Vue {
  onNavIconClick() {
    this.$store.dispatch('app/toggleNavigation');
  }

  get organizationName(): string {
    return this.$store.state.auth.organization.name;
  }
}
</script>

<style lang="scss" scoped>
.app-title {
  color: #FFF;
}

.alpha {
  color: red;
  font-size: 14px;
  font-weight: bold;
  font-style: italic;
  margin-left: 12px;
  vertical-align: .5em;
  overflow: visible;
  padding-right: 1px;
}
</style>
