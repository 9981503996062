<template>
  <div class="d-flex flex-column fill-height justify-center align-center">
    <v-card width="400">
      <v-toolbar color="primary" dark dense>
        <v-toolbar-title>{{ resetPassword ? $t('action.resetPassword') : $t('action.login') }}</v-toolbar-title>
        <v-spacer/>
        <language-selection v-model="$i18n.locale"/>
      </v-toolbar>
      <v-card-text>
        <reset-password-request-form v-if="resetPassword" @cancel="resetPassword=false" @success="resetPassword=false"/>
        <login-form v-else/>
      </v-card-text>
    </v-card>
    <a v-if="!resetPassword" @click="resetPassword=true" class="mt-2">{{ $t('q.passwordLost') }}</a>
  </div>
</template>

<script lang="ts">
import LanguageSelection from '@cat-europe/common/web/components/language-selection.vue';
import Vue from 'vue';
import {Component} from 'vue-property-decorator';
import LoginForm from '~/components/forms/login-form.vue';
import ResetPasswordRequestForm from '~/components/forms/reset-password-request-form.vue';

@Component({
  components: {LoginForm, ResetPasswordRequestForm, LanguageSelection},
})
export default class LoginPanel extends Vue {
  resetPassword = false;
}
</script>
