import {library} from '@fortawesome/fontawesome-svg-core';
import {faPaypal} from '@fortawesome/free-brands-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import Vue from 'vue';

/* add icons to the library */
library.add(faPaypal);

/* add font awesome icon component */
Vue.component('font-awesome-icon', FontAwesomeIcon);
