import Vue from 'vue';
import VueRouter, {RouteConfig} from 'vue-router';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {path: '/', name: 'root', redirect: 'products'},
  {path: '/user/profile', name: 'user-profile', component: () => import( /* webpackChunkName: "user-profile.page" */ './pages/user-profile.page.vue')},
  {
    path: '/organization/details',
    name: 'organization-details',
    component: () => import( /* webpackChunkName: "organization-details.page" */ './pages/organization-details.page.vue'),
  },
  {path: '/users', name: 'users', component: () => import( /* webpackChunkName: "users.page" */ './pages/users.page.vue')},
  {path: '/products', name: 'products', component: () => import( /* webpackChunkName: "products.page" */ './pages/product/products.page.vue')},
  {path: '/products/:id', name: 'product-details', component: () => import( /* webpackChunkName: "product-details.page" */ './pages/product/product-details.page.vue')},
  {path: '/orders', name: 'orders', component: () => import( /* webpackChunkName: "orders.page" */ './pages/order/orders.page.vue')},
  {path: '/orders/:id', name: 'order-details', component: () => import( /* webpackChunkName: "order-details.page" */ './pages/order/order-details.page.vue')},
  {path: '/inventory', name: 'inventory', component: () => import( /* webpackChunkName: "inventory.page" */ './pages/inventory.page.vue')},
  {path: '/change-email/confirm', name: 'change-email-confirm', component: () => import( /* webpackChunkName: "change-email-confirm.page" */ './pages/change-email-confirm.page.vue')},

  {path: '/payment/checkout', name: 'checkout', component: () => import( /* webpackChunkName: "checkout.page" */ './pages/payment/checkout.page.vue')},
  {path: '/payment/success', name: 'success', component: () => import( /* webpackChunkName: "success.page" */ './pages/payment/success.page.vue')},
  {path: '/payment/error', name: 'error', component: () => import( /* webpackChunkName: "error.page" */ './pages/payment/error.page.vue')},

  // 404 page needs to be last in routes array
  {path: '*', name: '404', component: () => import( /* webpackChunkName: "404.page" */ './pages/404.page.vue')},
];

export const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});
