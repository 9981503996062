import {i18n} from '@cat-europe/common';
import deepmerge from 'deepmerge';

export const en = deepmerge(i18n.en, {
  action: {
    addToBasket: 'Add to shopping cart',
    proceedToCheckout: 'Proceed to Checkout',
    continueWithPaypal: 'Continue with PayPal',
    finalizeOrder: 'Finalize Order',
    assignProductAccessPass: 'Assign {product} Access Pass',
    assignProduct: 'Assign Product',
    confirmRemoveLastProduct: 'Removing last Product',
  },
  label: {
    organizationDetails: 'Organization Details',
    orderConfirmed: 'Order Confirmed',
    inventory: 'Inventory',
    tax: 'Tax',
    subTotal: 'Subtotal',
    basket: 'Shopping Cart',
    estimatedRemainingEffort: 'Estimated Remaining Effort',
    progress: 'Progress',
    archive: 'Archive',
    analytics: 'Analytics',
    latestItems: 'Latest {items}',
    quickAccess: 'Quick Access',
    amount: 'Amount',
    quickQuestionnaire: 'Quick Questionnaire',
    currentlyOwned: 'Currently owned',
  },
  text: {
    sendInviteSuccess: 'The product invites have been sent successfully',
    confirmRemoveLastProduct: 'Are you sure, you want to remove the last product from this order? This will return you to the Products Page.',
    analysis: {
      questionnaireDescription: 'Click on the Bars in the Diagram to add Questions to the Questionnaire',
      noQuestionsSelected: 'No questions selected',
      noQuestionnaires: 'No questionnaires available',
    },
    checkout: {
      orderConfirmed: 'Your order {orderDetails} has been confirmed!',
      reviewOrder: 'You can see an overview of all your orders in the {orders} section.',
      managePurchases: 'To manage your purchased Products, please visit the {inventory}.',
      thanks: 'Thank you!',
      addressWarning: 'Orders cannot be placed until required Address Data is added to your {organizationDetails}.',
    },
  },
  dialog: {
    startOverQuestionnaire: 'Would you like to start a new instance of this questionnaire?',
    moveItemToArchive: 'Would you like to move "{item}" into the archive?',
    restoreItemFromArchive: 'Would you like to restore "{item}" from the archive?',
    repeatWrong: 'Start a new Questionnaire with all incorrect answered questions',
    confirmAssignment: 'One or more of the email addresses you have entered, already have an identical active Access Pass. Assign anyway?',
  },
  notify: {
    renameItemSuccess: '{item} has been renamed successfully.',
    resetItemSuccess: '{item} has been resetted successfully.',
    archiveItemSuccess: '{item} has been moved into the archive.',
    restoreItemSuccess: '{item} has been restored from the archive.',
    changeEmailLinkSentSuccess: 'An email has been sent to {email} to confirm the change.',
    feedbackSuccess: 'Thank you for your feedback! We will contact you via email if necessary.',
  },
  warning: {
    noValidAccessPasses: 'You do not have valid AccessPasses.',
  },
  rule: {
    pendingAssignmentAlreadyExists: 'Pending Assignment already exists',
    identicalAccessPassAlreadyActive: 'Identical Access Pass already active',
  },
  msg: {
    noPurchasesForCountry: 'Purchases are currently not enabled for your Country.',
    noPurchasesForReverseChargeWithoutVatId: 'Purchases from your country currently require your Organization to have a VAT ID.',
  },
});
