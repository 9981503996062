import {CountryData} from '@cat-europe/common';
import {Module} from 'vuex';
import {api} from '~/plugins/api';
import {RootState} from '~/store';

export interface AppState {
  showNavigation: boolean,
  isLoading: boolean,
  openRequests: number,
  isCountryActive: boolean,
  isReverseChargeCountry: boolean,
  untilCountryIsFetched: Promise<any>,
  country: CountryData,
}

let resolveUntilCountryIsFetched, rejectUntilCountryIsFetched;

export const appStore: Module<AppState, RootState> = {
  namespaced: true,
  state: {
    isCountryActive: null,
    isReverseChargeCountry: null,
    country: null,
    showNavigation: true,
    isLoading: false,
    untilCountryIsFetched: new Promise((resolve, reject) => {
      resolveUntilCountryIsFetched = resolve;
      rejectUntilCountryIsFetched = reject;
    }),
    openRequests: 0,
  },
  mutations: {
    START_REQUEST: (state) => {
      state.openRequests++;
    },
    STOP_REQUEST: (state) => {
      state.openRequests--;
    },
    SET_NAVIGATION: (state, showNavigation) => {
      state.showNavigation = showNavigation;
    },
  },
  actions: {
    startRequest({commit}) {
      commit('START_REQUEST');
    },
    stopRequest({commit}) {
      commit('STOP_REQUEST');
    },
    toggleNavigation({commit, state}) {
      commit('SET_NAVIGATION', !state.showNavigation);
    },
    setNavigation({commit}, isOpen: boolean) {
      commit('SET_NAVIGATION', isOpen);
    },
    showError(context, error: any) {
      console.log('showError', error);
    },
    async afterSuccessfulLogin(context) {
      await context.dispatch('fetchCountry');
    },
    async fetchCountry(context) {
      try {
        const response = await api.get('/api/organizations/country');
        context.state.isCountryActive = response.data.isActive;
        context.state.isReverseChargeCountry = response.data.reverseCharge;
        context.state.country = response.data.country;
        resolveUntilCountryIsFetched(true);
      } catch (e) {
        rejectUntilCountryIsFetched(e);
      }
    },
  },
  getters: {},
};
