<template>
  <div class="d-flex flex-column fill-height justify-center align-center">
    <v-card width="400">
      <v-toolbar color="primary" dark dense>
        <v-toolbar-title>{{ $t('action.selectNewPassword') }}</v-toolbar-title>
        <v-spacer/>
        <language-selection v-model="$i18n.locale"/>
      </v-toolbar>

      <v-form ref="form" v-model="isValid" @submit.prevent="submit">
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-text-field v-model="form.password" :label="$t('field.password')" :type="clearText ? 'text' : 'password'" :rules="ruleset.password" counter
                            @input="updateValidation()"/>
            </v-col>
            <v-col cols="12">
              <v-text-field v-model="passwordConfirm" :label="$t('field.passwordConfirm')" :type="clearText ? 'text' : 'password'" :rules="ruleset.confirmation" counter/>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn icon @click="clearText=!clearText">
            <v-icon>{{ clearText ? 'mdi-eye' : 'mdi-eye-off' }}</v-icon>
          </v-btn>
          <v-spacer/>
          <v-btn text @click="$router.push('/')">{{ $t('action.cancel') }}</v-btn>
          <v-btn color="primary" :disabled="!isValid" type="submit">{{ $t('action.submit') }}</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </div>
</template>

<script lang="ts">
import {getValidationMessages} from '@cat-europe/common';
import {User} from '@cat-europe/common/web';
import LanguageSelection from '@cat-europe/common/web/components/language-selection.vue';
import Vue from 'vue';
import {Component} from 'vue-property-decorator';

@Component({
  components: {LanguageSelection},
})
export default class ResetPasswordPage extends Vue {
  get title() {
    return this.$t('action.resetItem', {item: this.$t('field.password')});
  }

  get token() {
    return this.$route.query.t;
  }

  isValid = false;
  clearText = false;

  form = {
    password: '',
  };
  passwordConfirm = '';

  async submit() {
    try {
      await this.$api.put('/api/auth/reset-password', {...this.form, token: this.token});
      (this.$refs.form as HTMLFormElement).reset();
      this.$notify.success(this.$t('notify.changeItemSuccess', {item: this.$t('field.password')}));
      await this.$router.push('/');
    } catch (err) {
      this.$notify.error(err);
    }
  }

  get constraints() {
    return User.validation.constraints;
  }

  updateValidation() {
    return (this.$refs.form as HTMLFormElement).validate();
  }

  get ruleset() {
    const messages = getValidationMessages(this.$i18n.locale);
    return {
      password: [
        (v) => !!v || messages['any.required'].replace('{{#label}}', String(this.$t('field.password'))),
        (v) => !!v && v.length >= this.constraints.password.minLength || messages['string.min']
            .replace('{{#label}}', String(this.$t('field.password')))
            .replace('{{#limit}}', String(this.constraints.password.minLength)),
      ],
      confirmation: [
        (v) => !!v || messages['any.required'].replace('{{#label}}', String(this.$t('field.passwordConfirm'))),
        (v) => !!v && v === this.form.password || messages['any.match']
            .replace('{{#label}}', String(this.$t('field.passwordConfirm')))
            .replace('{{key}}', String(this.$t('field.password'))),
      ],
    };
  }
}
</script>
