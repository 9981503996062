<template>
  <v-form @submit.prevent="submit">
    <v-text-field prepend-icon="mdi-email" v-model="form.email" :label="$t('field.email')" autocomplete="username"/>
    <v-text-field prepend-icon="mdi-lock" v-model="form.password" :label="$t('field.password')" type="password" autocomplete="current-password"/>

    <div class="d-flex">
      <v-spacer/>
      <v-btn color="primary" type="submit">{{ $t('action.login') }}</v-btn>
    </div>
  </v-form>
</template>

<script lang="ts">
import Vue from 'vue';
import {Component} from 'vue-property-decorator';

@Component
export default class LoginForm extends Vue {
  form = {
    email: '',
    password: '',
  };

  async submit() {
    try {
      await this.$store.dispatch('auth/login', this.form);
    } catch (err) {
      this.$notify.error(err);
    }
  }
}
</script>
