<template>
  <v-navigation-drawer v-model="isOpen" app mobile-breakpoint="600" width="234" right disable-resize-watcher>
    <div class="header">
      <span class="title">{{ $t('label.basket') }}</span>
      <v-btn icon @click="isOpen=false" class="close-btn">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </div>
    <v-list v-if="order" class="grow" dense>
      <v-list-item v-for="item in order.items" class="cart-item" :key="item.product.id">
        <v-list-item-content>
          <v-list-item-title class="d-flex justify-space-between align-center" style="white-space: break-spaces">
            <span>{{ item.product.name }}</span>
            <v-btn small icon @click="removeProduct(item.product.id)">
              <v-icon small>mdi-delete</v-icon>
            </v-btn>
          </v-list-item-title>
          <v-list-item-subtitle class="d-flex justify-space-between align-center">

            <span>{{ item.product.price }} €</span>
            <div>
              <v-btn small icon :disabled="item.amount <= 1" @click="decreaseAmount(item.product.id)">
                <v-icon small>mdi-minus</v-icon>
              </v-btn>
              <span class="no-select mx-1">{{ item.amount }}</span>
              <v-btn small icon @click="increaseAmount(item.product.id)">
                <v-icon small>mdi-plus</v-icon>
              </v-btn>
            </div>
            <span>{{ item.product.price * item.amount }} €</span>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider class="my-1"/>

      <v-list-item class="total-item">
        <div v-if="order.subTotal > 0" class="total-item-row">
          <span>{{ $t('label.subTotal') }}</span>
          <span class="text-decoration-underline">{{ order.subTotal | formatPrice }} €</span>
        </div>
        <div v-if="order.subTotal > 0 && order.discount" class="total-item-row">
          <span>{{ $t('field.discount') }} ({{ order.discount }}%)</span>
          <span class="text-decoration-underline">{{ order.totalDiscountValue | formatPrice }} €</span>
        </div>
        <div v-if="order.subTotal > 0 && !order.vatExempt && !order.isReverseChargeCountry" class="total-item-row">
          <span>{{ $tc('p.tax', 2) }}  <span v-if="!order.hasMixedTaxes">({{ order.tax(order.items[0].product) }}%)</span></span>
          <span class="text-decoration-underline">{{ order.totalTaxValue | formatPrice }} €</span>
        </div>

        <div class="total-item-row total">
          <span>{{ $t('label.total') }}</span>
          <span class="text-decoration-underline">{{ order.total | formatPrice }} €</span>
        </div>
      </v-list-item>

      <v-list-item dense>
        <v-list-item-action>
          <v-btn color="primary" :to="{name: 'checkout'}">{{ $t('action.proceedToCheckout') }}</v-btn>
        </v-list-item-action>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script lang="ts">
import {OrderCore} from '@cat-europe/common';
import Vue from 'vue';
import {Component} from 'vue-property-decorator';
import {formatPrice} from '~/filters';

@Component({filters: {formatPrice}})
export default class AppBasket extends Vue {

  order: OrderCore = null;

  async mounted() {
    await this.$store.state.app.untilCountryIsFetched;
    this.order = new OrderCore(this.$store.state.basket.items, this.$store.state.auth.organization, this.$store.state.app.country);
  }

  // get items() {
  //   return this.$store.state.basket.items;
  // }

  get isOpen(): boolean {
    return this.$store.state.basket.isOpen;
  }

  set isOpen(isOpen: boolean) {
    this.$store.dispatch('basket/setIsOpen', isOpen);
  }

  // get subTotal() {
  //   return this.$store.state.basket.items.reduce((sum, item) => sum += item.amount * item.product.price, 0);
  // }
  //
  // get discount() {
  //   return this.$store.state.auth.organization.discount;
  // }
  //
  // get vatExempt() {
  //   return this.$store.state.auth.organization.vatExempt;
  // }
  //
  // get isReverseChargeCountry() {
  //   return this.$store.state.app.isReverseChargeCountry;
  // }
  //
  // get hasMixedTaxes() {
  //   const result = this.$store.state.basket.items.reduce((result, item) => ({
  //     reduced: result.reduced || item.product.reducedTax,
  //     normal: result.normal || !item.product.reducedTax,
  //   }), {reduced: false, normal: false});
  //   return result.reduced && result.normal;
  // }
  //
  // get discountValue() {
  //   return -this.subTotal * (this.discount / 100);
  // }
  //
  // get discountedSubTotal() {
  //   return this.subTotal + this.discountValue;
  // }
  //
  // get taxPercentage() {
  //   if (!this.$store.state.app.country) {
  //     return 0;
  //   }
  //   return this.$store.state.basket.items[0].product.reducedTax ? this.$store.state.app.country.reducedTax : this.$store.state.app.country.tax;
  // }
  //
  // get taxValue() {
  //   if (!this.$store.state.app.country) {
  //     return 0;
  //   }
  //   return this.$store.state.basket.items.reduce((value, item) => {
  //     const itemSum = item.amount * item.product.price;
  //     const discount = this.discount / 100;
  //     const discountedItemSum = itemSum - (itemSum * discount);
  //     const tax = item.product.reducedTax ? this.$store.state.app.country.reducedTax / 100 : this.$store.state.app.country.tax / 100;
  //     return value + (discountedItemSum * tax);
  //   }, 0);
  // }
  //
  // get total() {
  //   return (this.vatExempt || this.isReverseChargeCountry) ? this.discountedSubTotal : this.discountedSubTotal + this.taxValue;
  // }

  removeProduct(productId: number) {
    this.$store.commit('basket/remove', productId);
  }

  decreaseAmount(productId: number) {
    this.$store.commit('basket/decreaseAmount', productId);
  }

  increaseAmount(productId: number) {
    this.$store.commit('basket/increaseAmount', productId);
  }
}
</script>

<style lang="scss" scoped>
@import "../../styles/colors";

.header {
  position: relative;
  background-color: lighten($c-primary, 3);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 56px;
  color: #fff;
  font-weight: normal;
  box-shadow: 0 2px 4px -1px rgb(0 0 0 / 20%), 0 4px 5px 0 rgb(0 0 0 / 14%), 0 1px 10px 0 rgb(0 0 0 / 12%);
}

.close-btn {
  position: absolute;
  right: 5px;
  top: 5px;
  color: #fff !important;
}

.cart-item, .total-item {
  border: 1px solid rgba(#777, 0.1);
  border-radius: 4px;
  margin: 4px;
}

.v-list .total-item {
  border: 2px solid rgba(#777, 0.2);
  display: flex;
  flex-direction: column;
  min-height: auto;
  padding-top: 6px;
  padding-bottom: 6px;
}

.total-item-row {
  display: flex;
  width: 100%;
  justify-content: space-between;
  font-size: 0.9rem;

  &.total {
    margin-top: 4px;
    padding-top: 4px;
    border-top: 1px solid rgba(#000, 0.5);
    font-weight: bold;
  }
}
</style>
