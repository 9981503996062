<template>
  <v-app>
    <loading-indicator/>

    <v-main>
      <transition name="page" mode="out-in">
        <slot/>
      </transition>
    </v-main>

    <app-footer/>
  </v-app>
</template>

<script lang="ts">
import LoadingIndicator from '@cat-europe/common/web/components/loading-indicator.vue';
import Vue from 'vue';
import {Component} from 'vue-property-decorator';
import AppFooter from '~/components/layout/app-footer.vue';

@Component({
  components: {AppFooter, LoadingIndicator},
})
export default class BlankLayout extends Vue {
}
</script>


<style lang="scss">
.page-enter-active,
.page-leave-active {
  transition: opacity 0.15s, transform 0.2s;
}

.page-enter {
  opacity: 0;
  transform: translateY(-10%);
}

.page-leave-to {
  opacity: 0;
  transform: translateY(5%);
}
</style>
