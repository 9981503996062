<template>
  <!--  Application -->
  <component v-if="isAuthenticated" :is="layout">
    <router-view/>
  </component>

  <!--  Loading Indicator -->
  <blank-layout v-else-if="isAuthPending">
    <div class="d-flex fill-height justify-center align-center">
      <v-progress-circular size="100" indeterminate color="primary"/>
    </div>
  </blank-layout>

  <!--  reset-password-page Panel -->
  <blank-layout v-else-if="isPasswordResetPage">
    <reset-password-panel/>
  </blank-layout>

  <!--  Login Panel -->
  <blank-layout v-else>
    <login-panel/>
  </blank-layout>
</template>

<script lang="ts">
import {Model} from '@cat-europe/common/web';
import moment from 'moment';
import Vue from 'vue';
import {Component} from 'vue-property-decorator';
import LoginPanel from '~/components/auth/login-panel.vue';
import ResetPasswordPanel from '~/components/auth/reset-password-panel.vue';
import AppFooter from '~/components/layout/app-footer.vue';
import AppLayout from '~/layouts/app-layout.vue';
import BlankLayout from '~/layouts/blank-layout.vue';

@Component({
  components: {AppFooter, AppLayout, BlankLayout, LoginPanel, ResetPasswordPanel},
})
export default class App extends Vue {
  async created() {
    Model.setContext(this);
    await Promise.all([
      this.$store.dispatch('auth/loginByToken'),
      this.$store.commit('basket/loadFromLocalStorage'),
    ]);

    const locale = window.localStorage.getItem('locale') || this.$i18n.locale;
    moment.locale(locale);
    this.$i18n.locale = locale;
    this.$vuetify.lang.current = locale;

    window.addEventListener('focus', () => this.checkIfSessionStillValid());
  }

  get layout() {
    return this.$route.meta.layout || 'app-layout';
  }

  get isAuthenticated() {
    return this.$store.state.auth.isAuthenticated;
  }

  get isAuthPending() {
    return this.$store.state.auth.isAuthPending;
  }

  get isPasswordResetPage() {
    return this.$route.path === '/reset-password';
  }

  async checkIfSessionStillValid() {
    const isExpired = this.$store.state.auth.expiresAt < Date.now();
    if (this.$store.state.auth.expiresAt && isExpired) {
      try {
        await this.$store.dispatch('auth/refreshToken');
      } catch (e) {
        window.location.reload();
      }
    }
  }
}
</script>

<style lang="scss">
@import "styles";
</style>
