<template>
  <v-navigation-drawer class="app-navigation" v-model="isOpen" app clipped mobile-breakpoint="600" width="234">
    <v-list class="grow" dense>
      <template v-for="item in items">
        <v-list-group v-if="item.children" v-model="item.open" :key="item.title">
          <template #activator>
            <v-list-item-icon>
              <v-icon v-if="item.icon">{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </template>

          <v-list-item v-for="child in item.children" :key="child.title" :to="child.route" :exact="child.exact" :ripple="child.ripple">
            <v-list-item dense>
              <v-list-item-icon>
                <v-icon v-if="child.icon">{{ child.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ child.title }}</v-list-item-title>
            </v-list-item>
          </v-list-item>
        </v-list-group>

        <v-list-item v-else :key="item.title" :to="item.route" :exact="item.exact" :ripple="item.ripple">
          <v-list-item-icon>
            <v-icon v-if="item.icon">{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
          <v-badge v-if="item.badge" class="badge" color="orange" :content="item.badge"/>
        </v-list-item>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script lang="ts">
import Vue from 'vue';
import {Component} from 'vue-property-decorator';

@Component
export default class AppNavigation extends Vue {
  get items() {
    return [
      // {title: 'Home', icon: 'mdi-home', route: {name: 'home'}, exact: false},
      {title: this.$tc('p.product', 2), icon: 'mdi-cart', route: {name: 'products'}, exact: false},
      {title: this.$t('label.inventory'), icon: 'mdi-briefcase-arrow-left-right', route: {name: 'inventory'}, exact: false},
      {title: this.$tc('p.order', 2), icon: 'mdi-clipboard-text', route: {name: 'orders'}, exact: false},
    ];
  }

  get isOpen(): boolean {
    return this.$store.state.app.showNavigation;
  }

  set isOpen(value: boolean) {
    this.$store.dispatch('app/setNavigation', value);
  }
}
</script>

<style lang="scss" scoped>
@import "../../styles/colors";

.app-navigation::v-deep .v-list-item--active:not(.v-list-group__header) {
  background-color: lighten($c-primary, 5%);
  color: rgba(#fff, 0.9);

  & > .v-list-item {
    color: rgba(#fff, 0.9) !important;
  }

  .v-icon {
    color: $ci-orange;
  }
}

.badge {
  margin-top: 10px;
  margin-right: 10px;
}

.app-navigation ::v-deep .v-list-item__icon {
  margin-right: 16px;
}
</style>
