<template>
  <v-toolbar-items class="align-center">
    <tooltip-button v-if="showBasket" class="mr-2" icon="mdi-cart" :tooltip="$t('action.showItem', {item: $t('label.basket')})" color="orange" bottom @click="openBasket"/>

    <v-menu bottom offset-y transition="scale-transition" origin="top center" tile>
      <template #activator="{ on, attrs }">
        <v-btn text v-on="on" v-bind="attrs" :class="{'pl-0 ml-1': $vuetify.breakpoint.smAndUp}">
          <v-avatar v-if="user.avatar" width="28" height="28" :class="{'mr-2': $vuetify.breakpoint.smAndUp}">
            <!--          <v-img alt="Avatar" :src="user.avatar" contain/>-->
            <v-icon>mdi-account-circle</v-icon>
          </v-avatar>
          <v-icon v-else :left="$vuetify.breakpoint.smAndUp">mdi-account</v-icon>
          <span v-if="$vuetify.breakpoint.smAndUp">{{ user.email }}</span>
        </v-btn>
      </template>
      <v-list class="py-0">
        <template v-for="(item, index) in items">
          <v-menu v-if="item.children" :key="index" offset-x left open-on-hover>
            <template #activator="{on}">
              <v-list-item :to="item.to" v-on="on" @click.stop>
                <v-list-item-action>
                  <flag-icon :locale="$i18n.locale"/>
                </v-list-item-action>

                <v-list-item-content>
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                  <v-list-item-subtitle v-if="item.subTitle">{{ item.subTitle }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>
            <v-list>
              <v-list-item v-for="(child, idx) in item.children" @click="child.onClick" :key="'child-'+idx">
                <v-list-item-action>
                  <flag-icon :locale="child.value"/>
                </v-list-item-action>
                <v-list-item-title>{{ child.title }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>

          <v-list-item v-else :key="index" :to="item.to" v-on="item.onClick ? {click: item.onClick} : {}">
            <v-list-item-action v-if="item.icon">
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
              <v-list-item-subtitle v-if="item.subTitle">{{ item.subTitle }}</v-list-item-subtitle>
              <span v-if="index === items.length - 1" class="version-label">v{{ versionNumber }}</span>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-menu>
  </v-toolbar-items>
</template>

<script lang="ts">
import FlagIcon from '@cat-europe/common/web/components/flag-icon.vue';
import TooltipButton from '@cat-europe/common/web/components/tooltip-button.vue';
import moment from 'moment';
import Vue from 'vue';
import {Component} from 'vue-property-decorator';

@Component({
  components: {FlagIcon, TooltipButton},
})
export default class UserMenu extends Vue {
  get versionNumber() {
    const buildTime = process.env.BUILD_TIME || '';
    const version = process.env.BUILD_VERSION || '';
    const buildDate = buildTime.substr(0, 10);
    const buildDatePars = buildDate.split('-');
    if (buildDatePars.length !== 3) {
      return '--';
    }
    return `${version}.${buildDatePars[1]}${buildDatePars[2]}`;
  }

  get user() {
    return this.$store.state.auth.user;
  }

  openBasket() {
    this.$store.commit('basket/setIsOpen', true);
  }

  get showBasket() {
    return this.$route.name !== 'checkout' && !this.$store.state.basket.isOpen && this.$store.state.basket.items.length > 0;
  }

  get items() {
    return [
      {icon: 'mdi-account-circle', title: this.$tc('p.userProfile'), to: {name: 'user-profile'}},
      {icon: 'mdi-account-supervisor-circle', title: this.$t('label.organizationDetails'), to: {name: 'organization-details'}},
      {
        title: this.$t('label.interfaceLanguage'),
        subTitle: this.$t('language.' + this.$i18n.locale),
        children: [
          {value: 'de', title: this.$t('language.de'), onClick: () => this.onLanguageChange('de')},
          {value: 'en', title: this.$t('language.en'), onClick: () => this.onLanguageChange('en')},
        ],
      },
      {
        icon: 'mdi-power',
        title: this.$t('action.logout'),
        onClick: () => this.$store.dispatch('auth/logout'),
      },
    ];
  }

  onLanguageChange(locale: string) {
    this.$i18n.locale = locale;
    this.$vuetify.lang.current = locale;
    moment.locale(locale);
    window.localStorage.setItem('locale', locale);
  }
}
</script>

<style lang="scss" scoped>

.version-label {
  position: absolute;
  right: 5px;
  bottom: 5px;
  font-size: 12px;
  color: rgba(#777, 0.5);
  user-select: none;
}
</style>
