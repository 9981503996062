<template>
  <v-footer ref="footer" class="cat-footer">
    <div class="mx-4 d-flex justify-center align-center flex-column">
      <v-img src="/img/logo.png" contain width="60" height="50" transition="scale-transition"/>
      <h4>CAT-Europe</h4>
    </div>

    <v-card flat color="#f5f5f5">
      <v-card-text>
        <h4>{{ $t('label.imprint') }}</h4>
        <p>CAT Civil Aviation Training Europe e.K. | Hans-Jürgen Seibert | Am Flugplatz 6 | 67547 Worms | Germany</p>
        <p>Tel.: +49 6241 4000-40 | Fax: +49 6241 4000-50 | E-Mail: info@cat-europe.com | UST-ID: DE 149987453</p>
      </v-card-text>
    </v-card>

    <div class="mr-2">
      <v-btn class="ml-1" icon @click="openWindow('https://www.cat-europe.com/en/')">
        <v-icon>mdi-web</v-icon>
      </v-btn>
      <v-btn class="ml-1" icon @click="openWindow('https://www.facebook.com/cat.europe')">
        <v-icon>mdi-facebook</v-icon>
      </v-btn>
      <v-btn class="ml-1" icon @click="openWindow('mailto:info@cat-europe.com')">
        <v-icon>mdi-email</v-icon>
      </v-btn>
    </div>
  </v-footer>
</template>


<script lang="ts">
import Vue from 'vue';
import {Component} from 'vue-property-decorator';

@Component
export default class AppFooter extends Vue {
  openWindow(value: string) {
    window.open(value, '_blank');
  }
}
</script>

<style lang="scss" scoped>
.cat-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  z-index: 5;

  h4 {
    color: rgba(#000, 0.6);
  }

  p {
    margin-bottom: 2px;
    font-size: 12px;
  }
}
</style>
